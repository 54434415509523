import Avatar from "./avatar"
import Date from "./date"
import CoverImage from "../components/coverImage"
import { Link } from "gatsby"
import { StructuredText } from "react-datocms"
import { GatsbyImage } from "gatsby-plugin-image"

export default function HeroPost({ title, coverImage, date, excerpt, author, slug, content }) {
  return (
    <section>
      <div className="mb-8 md:mb-16">
        <CoverImage title={title} fluid={coverImage?.large} slug={slug} />
      </div>
      <div className="lg:block md:grid md:grid-cols-2 md:gap-x-16 lg:gap-x-8 mb-20 md:mb-28">
        <div>
          <h3 className="mb-4 text-4xl lg:text-6xl leading-tight">
            <Link to={`/posts/${slug}`} className="hover:underline">
              {title}
            </Link>
          </h3>
        </div>
        <div>
          {/* <StructuredText
            data={content}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsVisual":
                  const img = record.image as any
                  const video = record as any
                  return (
                    <div className="post__visual">
                      <GatsbyImage image={img?.gatsbyImageData} alt="" />
                    </div>
                  )
                case "DatoCmsExternalEmbed":
                  return <div className="post__visual">{video.externalVideo.url}</div>
                default:
                  return (
                    <>
                      <p>Don't know how to render a block!</p>
                      <pre>{JSON.stringify(record, null, 2)}</pre>
                    </>
                  )
              }
            }}
          /> */}
          <p className="text-lg leading-relaxed mb-4 text-left">{excerpt}</p>
          <div>
            <div className="mb-4 text-xs text-left">
              <Date dateString={date} />
            </div>
            <Avatar name={author?.name} picture={author?.picture} />
          </div>
        </div>
      </div>
    </section>
  )
}
