import HeroPost from "../components/heroPost"
import MoreStories from "../components/moreStories"
import Navigation from "../components/navigation"
import Section from "../components/section"
import Container from "../components/container"

import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"
import SectionSeparator from "../components/sectionSeparator"
import { useEffect, useRef } from "react"
import PostBody from "../components/postBody"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import Gallery from "react-photo-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

interface Photo {
  src: string
  width: number
  height: number
  alt?: string
}

export default function Index({ data }) {
  const { allPosts, allSections, allCopies, site, blog, allPages } = data

  const indexPage = allPages.nodes[0]
  const bgImage = indexPage?.backgroundImage.gatsbyImageData
  const image = indexPage?.image.url
  const sections = allSections.nodes

  const heroPost = allPosts.nodes[0]
  const morePosts = allPosts.nodes

  const allGalleryImages = allSections.nodes.map((s) => s.gallery).flat()

  const photos = allGalleryImages.map((imageObject) => {
    const image = getImage(imageObject.gatsbyImageData)
    const { width, height } = image.width && image.height ? image : { width: 4, height: 3 } // Przykładowe wartości
    return {
      src: image.images.fallback?.src || "",
      width: width,
      height: height,
    }
  })

  return (
    <div>
      <HelmetDatoCms seo={blog?.seo} favicon={site.favicon} />
      <Navigation sections={sections} />

      <main>
        <div className="relative w-full ">
          <section className="relative h-screen flex items-center justify-center bg-cover bg-center">
            {/* Background GIF */}
            <GatsbyImage
              image={bgImage}
              alt="Background Image"
              className="absolute top-0 left-0 w-full h-full"
              style={{ zIndex: -1 }}
            />
            <div
              className="absolute z-10 flex flex-col-reverse items-center justify-between gap-8 "
              style={{ maxWidth: "80%" }}
            >
              <img src={image} alt="SVG Logo" className="relative z-10 w-40 h-auto mb-8" />

              {/* Hero content */}
              {indexPage.header && (
                <div className=" text-center text-white">
                  <h1 className="text-5xl md:text-7xl font-extrabold mb-4">{indexPage.header}</h1>
                  {indexPage.subheader && <p className="text-5xl font-semibold">{indexPage.subheader}</p>}
                </div>
              )}
            </div>

            {/* Overlay for better contrast (optional) */}
            <div className="absolute inset-0 bg-black opacity-50 z-5"></div>
          </section>
        </div>

        {sections.map(({ header, description, name, href, background, gallery, external, textColor, desc }) => {
          if (!external) {
            return (
              <Section
                key={href.replace("/#", "")}
                id={href.replace("/#", "")}
                title={`${name} - ${header}`}
                bgColor={background?.hex}
                color={textColor?.hex}
              >
                {!desc.value ? (
                  <div className="mt-16 md:mt-8 mb-8 md:mb-4" style={{ color: textColor?.hex }}>
                    {description.includes("##")
                      ? description.split("##").map((part, key) => <div id={key}>{part}</div>)
                      : description || ""}
                  </div>
                ) : (
                  <div className="mt-16 md:mt-8 mb-8 md:mb-4" style={{ color: textColor?.hex }}>
                    <PostBody content={desc.value} />
                  </div>
                )}
                {gallery.length > 0 && (
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {gallery.map(({ gatsbyImageData: image }, id) => {
                      return (
                        <div key={id}>
                          <GatsbyImage
                            className="h-auto max-w-full rounded-lg"
                            image={image}
                            alt={`Image for ${image.title}`}
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </Section>
            )
          }
        })}

        <SectionSeparator />

        {heroPost && (
          <Container>
            <h3 className="font-extrabold tracking-wide leading-tight text-left text-4xl md:text-7xl mb-4 md:mb-12">
              Blog
            </h3>
            <HeroPost
              title={heroPost.title}
              coverImage={heroPost.coverImage}
              date={heroPost.date}
              author={heroPost.author}
              slug={heroPost.slug}
              excerpt={heroPost.excerpt}
              content={heroPost.content}
            />
          </Container>
        )}

        {morePosts.length > 0 && (
          <Container>
            <MoreStories posts={morePosts} />
          </Container>
        )}
      </main>
    </div>
  )
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        content {
          value
        }
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 750)
        }
        author {
          name
          picture {
            gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
          }
        }
      }
    }
    allSections: allDatoCmsSection(sort: { fields: index, order: ASC }, limit: 20) {
      nodes {
        name
        background {
          hex
        }
        description
        desc {
          value
        }
        external
        textColor {
          hex
        }
        gallery {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }
        header
        href
        id
        index
        meta {
          publishedAt
        }
      }
    }
    allCopies: allDatoCmsCopy {
      nodes {
        heroContent
      }
    }
    allPages: allDatoCmsPage {
      nodes {
        name
        header
        subheader
        image {
          url
        }
        backgroundImage {
          gatsbyImageData
          blurhash
        }
      }
    }
  }
`
